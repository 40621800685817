import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import MainLayout from "./layout/main/main-layout";
import { lazy } from "react";
import ErrorBoundary from "./components/error-boundary";

const SignIn = lazy(() => import("./page/signin/signin"));
const Analytics = lazy(() => import("./page/analytics"));
const Payments = lazy(() => import("./page/profile/payment"));
const PracticeReport = lazy(() => import("./page/report/PracticeReport"));
const MockReport = lazy(() => import("./page/report/MockReport"));
const Dashboard = lazy(() => import("./page/dashboard"));
const ProfileLayout = lazy(() => import("./layout/profile/profile-layout"));
const CreateProfile = lazy(() => import("./page/profile/create"));
const ProfileHome = lazy(() => import("./page/profile/home"));
const SelectExams = lazy(() => import("./page/profile/select-exams"));
const SelectTopic = lazy(() => import("./page/select-topic/select-topic"));
const Question = lazy(() => import("./page/question"));
const ViewPlans = lazy(() => import("./page/profile/view-plans"));
const PracticeRules = lazy(() => import("./page/question/practice-rules"));
const Instructions = lazy(() => import("./page/question/instructions"));
const UserProfile = lazy(() => import("./page/profile/user"));
const ForgotPassword = lazy(() => import("./page/forgot-password"));
const FreeTrailSuccess = lazy(() => import("./page/profile/free-trial"));
const NotFound = lazy(() => import("./page/404"));

export const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <Outlet></Outlet>
      </ErrorBoundary>
    ),
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="signin" />,
          },
          {
            path: "dashboard",
            index: true,
            element: <Dashboard />,
          },
          {
            path: "practice",
            children: [
              {
                path: "report/:examProfileId",
                element: <PracticeReport />,
              },
              {
                path: ":featureId/:subjectId/:subjectName",
                element: <SelectTopic isMock={false} />,
              },
              {
                path: ":featureId/:subjectId/:subjectName/instructions",
                element: <PracticeRules />,
              },
              {
                path: ":featureId/:subjectId/:subjectName/:topicId/:qComplexityTypeID",
                element: <Question />,
              },
            ],
          },
          {
            path: "mock",
            children: [
              {
                path: ":featureId/:subjectId/:subjectName",
                element: <SelectTopic isMock={true} />,
              },
              {
                path: ":featureId/:subjectId/:subjectName/:topicId/:qComplexityTypeID",
                element: <Question />,
              },
              {
                path: "report/:examProfileId",
                element: <MockReport />,
              },
              {
                path: ":featureId/:subjectId/:subjectName/instructions",
                element: <Instructions />,
              },
            ],
          },

          {
            path: "analytics",
            element: <Analytics />,
          },
          // {
          //   path: "tips",
          //   element: "Tips",
          // },
          {
            path: "profile/user",
            element: <UserProfile />,
          },
        ],
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignIn isSignUp={true} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/profile",
        element: <ProfileLayout />,
        children: [
          {
            path: "",
            element: <ProfileHome />,
          },
          {
            path: "exams",
            element: <SelectExams />,
          },
          {
            path: "view-plans",
            element: <ViewPlans />,
          },
          {
            path: "view-addons/:addonId",
            element: <ViewPlans isAddOn={true} />,
          },
          {
            path: "free-trial",
            element: <FreeTrailSuccess />,
          },
        ],
      },
      {
        path: "/profile/pay",
        element: <Payments />,
      },
      {
        path: "profile/create",
        element: <CreateProfile />,
      },
      {
        path: "profile/edit",
        element: <CreateProfile isEdit={true} />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
